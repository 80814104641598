<template>
	<div class="container-fluid">
		<div class="row justify-content-center position-relative p-3 p-md-5">
			<div class="position-absolute bg-credito" style="bottom: 0px; left: 0px; right: 0px; top: 50%; display: block;"></div>
			<div class="col-lg-7">
				<div class="row align-items-stretch justify-content-center">
					<div class="col-10 col-md-4 d-flex flex-column justify-content-center mb-3">
						<div class="overflow-hidden rounded-sm mb-3 text-center">
							<img src="@/assets/image/vehiculos.webp" class="img-fluid" alt="Vehículos">
						</div>
						<div class="text-center mb-3">
							<img src="@/assets/image/flechas_azules.webp" class="img-fluid d-inline-block d-md-none" style="width: 20px;" alt="Flecha">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid d-none d-md-inline-block" style="width: 20px;" alt="Flecha">
						</div>
						<div class="text-center">
							<img src="@/assets/image/car_blue.webp" class="img-fluid d-inline-block d-md-none" style="width: 75px;" alt="Vehículo">
							<img src="@/assets/image/car.webp" class="img-fluid d-none d-md-inline-block" style="width: 75px;" alt="Vehículo">
						</div>
						<br>
						<h5 class="font-weight-bold text-center">Vehículo</h5>
						
						<div class="text-center">
							<router-link :to="{name:'precalifique.vehiculos.index'}" class="btn btn-primary rounded-pill font-weight-bold">
								¡Solicita tu préstamo!
							</router-link>
						</div>
					</div>
					<div class="col-md-8 mb-3">
						<div class="card overflow-hidden h-100 rounded-sm bg-secondary">
							<div class="card-header p-2">
								<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
							</div>
							<div class="card-body bg-light">
								<div class="pr-md-5">
									<ul>
										<li>Título de propiedad del vehículo</li>
										<li>Tarjeta de circulación</li>
										<li>Licencia de conducir vigente</li>
										<li>DPI vigente</li>
										<li>Recibo de agua, luz o teléfono fijo (mínimo 2)</li>
										<li>Últimos 3 estados de cuenta</li>
										<li>Copia de llave de encendido de el vehículo</li>
									</ul>
									<p>
										<strong>Si el vehículo está a nombre de persona jurídica</strong>
										(todo lo anterior) y adicional a:
									</p>
									<ul>
										<li>Copia de nombramiento del representante legal</li>
										<li>DPI del representante legal</li>
										<li>Copia de asamblea general de accionistas extraordinario
											y totalitario (autorizando a representante legal a poner
										bien como garantía)</li>
										<li>Copia escritura constitución sociedad</li>
										<li>Copia de patentes de comercio y sociedad</li>
										<li>Copia de RTU de la empresa</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center position-relative p-3 p-md-5">
			<div class="position-absolute bg-credito" style="bottom: 0px; left: 0px; right: 0px; top: 50%; display: block;"></div>
			<div class="col-lg-7">
				<div class="row align-items-stretch justify-content-center">
					<div class="col-10 col-md-4 mb-3 text-center">
						<div class="overflow-hidden rounded-sm mb-3">
							<img src="@/assets/image/reloj.webp" class="img-fluid rounded-sm" alt="Reloj">
						</div>
					</div>
					<div class="col-md-8 mb-3 d-flex flex-column">
						<div class="card mb-3 overflow-hidden h-100 rounded-sm bg-secondary">
							<div class="card-header p-2">
								<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
							</div>
							<div class="card-body bg-light">
								<div class="d-flex align-items-center h-100">
									<ul class="py-3">
										<li class="mb-2">DPI vigente</li>
										<li class="mb-2">Recibo de agua, luz o teléfono fijo (mínimo 2)</li>
										<li class="mb-2">Últimos 3 estados de cuenta</li>
										<li class="mb-2">Caja original y factura de compra (opcional)</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3 text-center">
						<div class="text-center mb-3">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid" style="width: 20px;" alt="Flecha">
						</div>
						<div class="text-center">
							<img src="@/assets/image/reloj-icono.webp" class="img-fluid" style="width: 75px;" alt="Reloj">
						</div>
						<br>
						<h5 class="font-weight-bold text-center">Relojes de alta gama</h5>
					</div>
					<div class="col-md-8 mb-3 d-flex flex-column">
						<div class="text-center my-auto">
							<router-link :to="{name:'relojes.index'}" class="btn btn-primary rounded-pill font-weight-bold">
								¡Solicita tu préstamo!
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center position-relative p-3 p-md-5">
			<div class="position-absolute bg-inversion" style="bottom: 0px; left: 0px; right: 0px; top: 50%; display: block;"></div>
			<div class="col-lg-7">
				<div class="row justify-content-center">
					<div class="col-10 col-md-4 d-flex flex-column justify-content-center mb-3">
						<div class="overflow-hidden rounded-sm mb-3">
							<img src="@/assets/image/inversion.webp" class="img-fluid rounded-sm" alt="Inversión">
						</div>
						<br>
						<div class="text-center mb-3">
							<img src="@/assets/image/flechas_verdes.webp" class="img-fluid d-inline-block d-sm-none" style="width: 20px;" alt="Flecha">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid d-none d-sm-inline-block" style="width: 20px;" alt="Flecha">
						</div>
						<div class="text-center mb-3">
							<img src="@/assets/image/mano.webp" class="img-fluid" style="width: 75px;" alt="Inversionista">
						</div>
						<br>
						<div class="text-center">
							<router-link :to="{name:'opciones-inversion.index'}" class="btn btn-primary rounded-pill font-weight-bold">
								¡Empieza a Invertir!
							</router-link>
						</div>
					</div>
					<div class="col-md-8 mb-3">
						<div class="card overflow-hidden rounded-lg bg-inversion">
							<div class="card-header p-2">
								<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos inversionista</h5>
							</div>
							<div class="card-body bg-light">
								<div>
									<ul>
										<li>DPI vigente</li>
										<li>Recibo de agua, luz ó teléfono fijo</li>
										<li>Fondos a invertirse deben estar bancarizados</li>
									</ul>
									<p>
										<strong>
										Si eres una persona jurídica (todo lo anterior) y adicional a:
										</strong>
									</p>
									<ul>
										<li>Copia de nombramiento del representante legal</li>
										<li>DPI del representante legal</li>
										<li>Copia escritura constitución sociedad</li>
										<li>Copia de patentes de comercio y sociedad</li>
										<li>Copia de RTU de la empresa</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row justify-content-center position-relative p-3 p-md-5" v-if="false">
			<div class="position-absolute bg-credito" style="bottom: 0px; left: 0px; right: 0px; top: 50%; display: block;"></div>
			<div class="col-lg-7">
				<div class="w-50 w-lg-25 mx-auto text-center mb-4">
					<img src="@/assets/image/logo_dark.webp" class="img-fluid">
				</div>
				<h3 class="text-center mb-5">Requisitos para la compra de vehículo con financiamiento</h3>
				<div class="row align-items-stretch justify-content-center">
					<div class="col-10 col-md-4 mb-3 text-center">
						<div class="overflow-hidden rounded-sm mb-3">
							<img src="@/assets/image/auto-comprar-vehiculos.webp" class="img-fluid rounded-sm" alt="Reloj">
						</div>
					</div>
					<div class="col-md-8 mb-3 d-flex flex-column">
						<div class="card mb-3 overflow-hidden h-100 rounded-sm bg-secondary">
							<div class="card-header p-2">
								<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
							</div>
							<div class="card-body bg-light">
								<div class="d-flex align-items-center h-100">
									<ul class="py-3">
										<li class="mb-2">Licencia de conducir vigente</li>
										<li class="mb-2">DPI vigente</li>
										<li class="mb-2">Recibo de agua, luz o teléfono fijo (minimo 2)</li>
										<li class="mb-2">Últimos 3 estados de cuenta</li>
										<li class="mb-2">Solicitud de financiamiento</li>
										<li class="mb-2">Estado patrimonial</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
					<div class="col-md-4 mb-3 text-center">
						<div class="text-center mb-3">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid" style="width: 20px;" alt="Flecha">
						</div>
						<div class="text-center">
							<img src="@/assets/image/auto-compras-blanco.webp" class="img-fluid" style="width: 75px;" alt="Reloj">
						</div>
						<br>
						<h5 class="font-weight-bold text-center">Compra de <br>vehículo</h5>
					</div>
					<div class="col-md-8 mb-3"></div>
				</div>
			</div>
		</div>

		<div class="row justify-content-center position-relative p-3 p-md-5">
			<div class="position-absolute bg-credito" style="bottom: 0px; left: 0px; right: 0px; top: 40%; display: block; border-radius: 0px 0px 24px 24px;"></div>
			<div class="col-lg-7">
				<div class="w-50 w-lg-25 mx-auto text-center mb-4">
					<img src="@/assets/image/logo_dark.webp" class="img-fluid" alt="Club Cash-In">
				</div>
				<h3 class="text-center mb-5">Requisitos para la compra de vehículo con financiamiento</h3>
				<div class="row align-items-stretch justify-content-center">
					<div class="col-10 col-md-4 mb-3 text-center">
						<div class="overflow-hidden rounded-sm mb-3">
							<img src="@/assets/image/auto-comprar-vehiculos.webp" class="img-fluid rounded-sm" alt="Reloj">
						</div>

						<div class="text-center mb-3">
							<img src="@/assets/image/arrow_down_white.webp" class="img-fluid" style="width: 20px;" alt="Flecha">
						</div>
						<div class="text-center">
							<img src="@/assets/image/auto-compras-blanco.webp" class="img-fluid" style="width: 75px;" alt="Reloj">
						</div>
						<br>
						<img src="@/assets/image/auto-compras.webp" class="img-fluid" style="width: 130px;" alt="Auto compras">
						<h5 class="font-weight-bold text-center" v-if="false">Compra de <br>vehículo</h5>
					</div>
					<div class="col-md-8 mb-3 d-flex flex-column">
						<div class="card mb-3 overflow-hidden h-100 rounded-sm bg-secondary">
							<div class="card-header p-2">
								<h5 class="card-title mb-0 font-weight-bold mx-3">Requisitos</h5>
							</div>
							<div class="card-body bg-light">
								<p class="font-weight-bold">Compra de vehículo en nuestra plataforma</p>

								<ul class="py-3">
									<li class="mb-2">Licencia de conducir vigente</li>
									<li class="mb-2">DPI vigente</li>
									<li class="mb-2">Recibo de agua, luz o teléfono fijo (minimo 2)</li>
									<li class="mb-2">Últimos 3 estados de cuenta</li>
									<li class="mb-2">Solicitud de financiamiento</li>
									<li class="mb-2">Estado patrimonial</li>
								</ul>
								<div class="d-flex align-items-center h-100" v-if="false">
								</div>

								<p class="font-weight-bold">Si es persona jurídica:</p>

								<ul class="py-3">
									<li class="mb-2">(Todo lo anterior) y adicional a:</li>
									<li class="mb-2">Título de propiedad del vehículo</li>
									<li class="mb-2">Tarjeta de circulación</li>
									<li class="mb-2">Copia de llave de encendido del vehículo</li>
								</ul>
							</div>
						</div>
						<p class="text-white">
							* Si el carro se encuentra a nombre de una empresa también podemos apoyarte, ponte en contacto con nosotros, será un gusto poder atenderte!
						</p>
					</div>
					<div class="col-md-4 mb-3 text-center">
						
					</div>
					<div class="col-md-8 mb-3"></div>
				</div>
			</div>
		</div>
	</div>
</template>