<template>
	<div :class="{'container-fluid':true, 'bg-secondary':!isMobile, 'bg-white':isMobile}">
		<div class="row justify-content-center align-items-center">
			<div :class="{'col-lg-8 order-1 order-lg-0':true, 'mt-3 mb-3':isMobile}">
				<h1 :class="{'text-center font-weight-bold mb-0 display-4':true, 'text-white':!isMobile}">¿CON QUE GARANTÍA QUIERES SOLICITAR TU PRÉSTAMO?</h1>
			</div>

			<div :class="{'col-lg-4':true, 'bg-secondary text-left':isMobile, 'text-left':!isMobile}">
				<img src="@/assets/image/garantia-baner.webp" class="img-fluid" alt="Hombre" style="width:300px;">
			</div>
		</div>
	</div>

	<div class="container-fluid bg-white">
		<div class="row">
			<div class="col-md-12">
				<h1 class="text-center text-primary mb-5 mt-5" style="font-weight: lighter;">Buscamos la cuota ideal para ti. </h1>
				<div class="row justify-content-center">
					<div class="col-md-8 col-lg-6">
						<div class="mb-5" v-for="(precalifique, index) in precalificaciones" :key="index">
							<div class="media">
								<div class="mr-3">
									<img v-lazy="precalifique.image" :alt="precalifique.name" class="img-fluid">
								</div>
								<div class="media-body">
									<h5 class="mt-0 font-weight-bold" v-text="precalifique.name"></h5>
									<p v-html="precalifique.description" class="mb-1"></p>
									<div class="">
										<div class="row align-items-center">
											<div :class="{'col-lg-6 text-center':true, 'mb-4 mt-4':isMobile}">
												<router-link :to="{name: precalifique.route2}" class="btn btn-inversion rounded-pill font-weight-bold mr-2" v-if="precalifique.route2">
													Nuevo
												</router-link>

												<router-link :to="{name: precalifique.route}" class="btn btn-secondary rounded-pill font-weight-bold">
													{{precalifique.text || 'Precalifique'}}
												</router-link>
											</div>
											<div class="col-lg-6">
												<div class="accordion" id="accordionExample">
													<div class="" :id="precalifique.head">
														<h2 class="mb-0">
															<button class="btn btn-block text-left d-flex align-items-center justify-content-between rounded-0 collapsed" style="border-bottom: 1px solid;" type="button" data-toggle="collapse" :data-target="precalifique.id" aria-expanded="false" :aria-controls="precalifique.collapse" v-if="precalifique.cuerpo">
																<span class="h3 text-primary">Requisitos</span>
																<img src="@/assets/image/down-arrow.webp" alt="ver más">
															</button>
														</h2>
													</div>	
												</div>
											</div>
											<div class="col-lg-12" v-if="precalifique.cuerpo">
												<div :id="precalifique.collapse" class="collapse" :aria-labelledby="precalifique.head" data-parent="#accordionExample">
													<div class="card-body">
														<div class="pr-md-5">
															<p v-html="precalifique.cuerpo" class="mb-1"></p>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container bg-">
		<div class="row jusify-content-center align-items-center">
			<div class="col-lg-5 text-right mt-3 mb-3">
				<h1 class="font-weight-bold Aqum display-4 text-center" v-if="isMobile">VENDE TU CARRO</h1>
				<img src="@/assets/image/vende-tu-carro.webp" class="img-fluid" alt="">
			</div>
			<div class="col-lg-7 text-center mt-3 mb-3">
				<h1 class="font-weight-bold Aqum display-4" v-if="!isMobile">VENDE TU CARRO</h1>
				<p class="h2" style="font-weight: lighter;">¡Fácil, rápido y gratis!</p>
				<p class="h3" style="font-weight: lighter;">Con nosotros también puedes vender tu carro y si tu cliente quiere financiamiento nosotros se lo damos</p>
				<div class="mt-5">
					<router-link class="btn btn-secondary px-5" :to="{name:'vehiculos.create', hash:'#publicar'}">
						Publicar
					</router-link>
				</div>
			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
	import VehiculosIMG from '@/assets/image/sobre-vehiculo.webp'
	import AutoComprasIMG from '@/assets/image/auto-compras-icono.webp'

	export default {
		data() {
			return {
				precalificaciones: [
					{
						"name": "Préstamo por tu carro",
						"image": VehiculosIMG,
						"description": "Préstamo con tu carro como garantía. Fácil, rápido y barato. <br class='d-none'>Buscamos la cuota ideal para tu bolsillo!",
						"route": "precalifique.vehiculos.index",
						"id": "#collapseTwo",
						"collapse": "collapseTwo",
						"head": "headingTwo",
						"cuerpo": "<ul><li>Título de propiedad del vehículo</li><li>Tarjeta de circulación</li><li>Licencia de conducir vigente</li><li>DPI vigente</li><li>Recibo de agua, luz o teléfono fijo (mínimo 2)</li><li>Últimos 3 estados de cuenta</li><li>Copia de llave de encendido de el vehículo</li></ul><p><strong>Si el vehículo está a nombre de persona jurídica</strong> (todo lo anterior) y adicional a:</p><ul><li>Copia de nombramiento del representante legal</li><li>DPI del representante legal</li><li>Copia de asamblea general de accionistas extraordinario y totalitario (autorizando a representante legal a poner bien como garantía)</li><li>Copia escritura constitución sociedad</li><li>Copia de patentes de comercio y sociedad</li><li>Copia de RTU de la empresa</li></ul>"
					},
					{
						"name": "Préstamo Auto-Compras",
						"image": AutoComprasIMG,
						"description": "Préstamo para comprar tu carro. <br class='d-none'>Buscamos la cuota ideal para tu bolsillo!",
						"route": "precalifique.auto-compras.index",
						"id": "#collapseFour",
						"collapse": "collapseFour",
						"head": "headingFour",
						"cuerpo": "<p><strong>Compra de vehículo en nuestra plataforma</strong></p><ul><li>Licencia de conducir vigente</li><li>DPI vigente</li><li>Recibo de agua, luz o teléfono fijo (minimo 2)</li><li>Últimos 3 estados de cuenta</li><li>Solicitud de financiamiento</li><li>Estado patrimonial</li></ul><p><strong>Si es persona jurídica:</strong></p><ul><li>(Todo lo anterior) y adicional a:</li><li>Título de propiedad del vehículo</li><li>Tarjeta de circulación</li><li>Copia de llave de encendido del vehículo</li></ul><p><strong>* Si el carro se encuentra a nombre de una empresa también podemos apoyarte, ponte en contacto con nosotros, será un gusto poder atenderte!</strong></p>",
						"text": "Usado",
						"route2": "precalifique.auto-compras-nuevos.index"
					}
				]
			}
		},

		computed: {
			isMobile() {
				return this.$store.getters.isMobile
			},
		}
	}
</script>